import { useState, useEffect } from 'react';
import { Navigation } from './components/navigation';
import { Header } from './components/header';
import { Features } from './components/features';
import { About } from './components/about';
import { Services } from './components/services';
import { Blogs } from './components/blogs';
import { Gallery } from './components/gallery';
import { Testimonials } from './components/testimonials';
import MobileRedirect from './components/MobileRedirect';
import { Team } from './components/Team';
import { Contact } from './components/contact';
import Map from './components/Map';
import { Footer } from './components/footer';
import WeatherStation from './components/WeatherStation';
import JsonData from './data/data.json';
import SmoothScroll from 'smooth-scroll';
import i18n from './i18n';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Routes,
	Link,
	Navigate,
} from 'react-router-dom';
import PrivacyRedirect from './components/PrivacyRedirect';
import TermsRedirect from './components/TermsRedirect';

export const scroll = new SmoothScroll('a[href*="#"]', {
	speed: 1000,
	speedAsDuration: true,
});

const App = () => {
	const [landingPageData, setLandingPageData] = useState({});
	useEffect(() => {
		setLandingPageData(JsonData);
	}, []);
	//   useEffect(() => {
	//     fetch('https://ipapi.co/json/')
	//     .then( res => res.json())
	//     .then(response => {
	//      console.log("Country is : ", response);
	//    })
	//    .catch((data, status) => {
	//      console.log('Request failed:', data);
	//    });
	//  },[])

	return (
		<div>
			<div
				className="app"
				style={{ backgroundImage: `url(${i18n.t('home_img')})` }}
			>
				<Navigation />
				<Header data={landingPageData.Header} />
				{/* <About data={landingPageData.About} /> */}
			</div>
			<div>
				<Features data={landingPageData.Features} />
				{/* <WeatherStation /> */}
				<Services data={landingPageData.Services} />
				<Blogs data={landingPageData.Blogs} />
				<Map />
				<Contact data={landingPageData.Contact} />
				<Footer data={landingPageData.Footer} />
				{/* <Link to="/mobile">Link</Link> */}

				{/* <Router> */}
				<Routes>
					<Route path="/mobile" element={<MobileRedirect />} />
					<Route path="/privacy" element={<PrivacyRedirect />} />
					<Route path="/terms" element={<TermsRedirect />} />
				</Routes>
				{/* </Router> */}
			</div>
		</div>
	);
};

export default App;

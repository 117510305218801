import i18n from "../i18n";
let classNameDependsOnCondtion = i18n.language == 'ar' ? "service_arabic" : "";
export const Services = (props) => {
  return (
    <div id='services' className='text-center'>
      <div className={`container-fluid ${classNameDependsOnCondtion }`}>
        <div className='section-titl'>
          <h2>{i18n.t("service_title")}</h2>
          <h3 style={{textAlign:'center'}}>
          {i18n.t("service_paragraph")}
          </h3>
        </div>
        <div className='row ser'>
          {props.data
            ? props.data.map((d, i) => (
              <div key={`${d.name}-${i}`} className={`col-md-4 ${classNameDependsOnCondtion }`}>
                {' '}
                {/* <i className={d.icon}></i> */}
                <img src={d.icon} className='feature-image' style={{width:'60%'}} alt='' />{' '}
                <div className='blog-desc'>
                  <br></br>
                  <div style={{height:'calc(5em )'}}>
                  <h1>{i18n.t(d.name)}</h1>
                  </div>
                  <br></br>
                  <p>{i18n.t(d.text)}</p>
                </div>
              </div>
            ))
            : 'loading'}
        </div>
      </div>
    </div>
  )
}

import { useState, useRef } from 'react';
import emailjs from 'emailjs-com';
import { FiCheckCircle } from 'react-icons/fi';

import i18n from '../i18n';

let classNameDependsOnCondtion = i18n.language == 'ar' ? 'arabic' : '';
// let reverse = i18n.language == 'ar' ? 'rtl' : 'ltr';
const initialState = {
	name: '',
	email: '',
	message: '',
	phone: '',
};

export const Contact = (props) => {
	const [{ name, email, phone, message }, setState] = useState(initialState);
	const [isFormSubmitted, setIsFormSubmitted] = useState(false);
	const [loading, setLoading] = useState(false);

	const form = useRef();

	const handleChange = (e) => {
		const { name, value } = e.target;
		setState((prevState) => ({ ...prevState, [name]: value }));
	};
	const clearState = () => setState({ ...initialState });

	const handleSubmit = (e) => {
		e.preventDefault();
		setLoading(true);

		// console.log(name, email, phone, message);
		emailjs
			.sendForm(
				'service_ln7e9bm',
				'template_4yjhvmr',
				form.current,
				'eXWVQW5UUK3AM-E_w'
			)
			.then(
				(result) => {
					console.log(result.text);
					clearState();
					setLoading(false);
					setIsFormSubmitted(true);
				},
				(error) => {
					console.log(error);
				}
			);
	};
	return (
		<div>
			<div id="contact">
				<div
					id="contact_back"
					className={`container-fluid contact-back ${classNameDependsOnCondtion}`}
				>
					<div className="row justify-content-center">
						<div className="section-titl col-lg-5 ml">
							<h2>
								{i18n.t('Get In')} <span>{i18n.t('Touch')}</span>{' '}
							</h2>
							<div className="contact-info">
								<div className="contact-item">
									<p>{i18n.t(props.data ? props.data.address : 'loading')}</p>
								</div>
								<div className="contact-item">
									<p>{props.data ? props.data.email : 'loading'}</p>
								</div>
								<div className="contact-item">
									<p>{props.data ? props.data.phone : 'loading'}</p>
								</div>
							</div>
						</div>
						<div className="col-lg-5">
							{!isFormSubmitted ? (
								<form
									name="sentMessage"
									validate
									onSubmit={handleSubmit}
									ref={form}
								>
									<div className="">
										<div className="form-group">
											<input
												type="text"
												id="name"
												name="name"
												className="form-control"
												placeholder={i18n.t('Name')}
												required
												onChange={handleChange}
											/>
											<p className="help-block text-danger"></p>
										</div>
									</div>
									<div className="">
										<div className="form-group">
											<input
												type="email"
												id="email"
												name="email"
												className="form-control"
												placeholder={i18n.t('Email')}
												required
												onChange={handleChange}
											/>
											<p className="help-block text-danger"></p>
										</div>
									</div>
									<div className="">
										<div className="form-group">
											<input
												type="text"
												id="phone"
												name="phone"
												className="form-control"
												placeholder={i18n.t('Phone Number')}
												required
												onChange={handleChange}
											/>
											<p className="help-block text-danger"></p>
										</div>
									</div>
									<div className="form-group">
										<textarea
											name="message"
											id="message"
											className="form-control"
											rows="4"
											placeholder={i18n.t('Message')}
											required
											onChange={handleChange}
										></textarea>
										<p className="help-block text-danger"></p>
									</div>
									<div className="d-flex justify-content-end">
										<button type="submit" className="btn btn-custom btn-lg">
											{loading
												? `${i18n.t('Sending...')}`
												: `${i18n.t('Send')}`}
										</button>
									</div>
								</form>
							) : (
								<div
									/**to be modified */
									// style={{ direction: `${reverse}` }}
									className="success__message-container"
								>
									<FiCheckCircle className="success__message-icon" />
									<span
										className={`success__message-content ${classNameDependsOnCondtion}`}
									>
										{i18n.t(props.data ? props.data.Success_Message : '')}
									</span>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

import i18n from '../i18n';
import Tridi from 'react-tridi';
import 'react-tridi/dist/index.css';
import React, { useState, useRef } from 'react';

let classNameDependsOnCondtion = i18n.language == 'ar' ? 'arabic' : '';
let padd_product = i18n.language == 'ar' ? 'padd_product' : '';
export const Features = (props) => {
	let colors = ['black', '#94ab53', 'black'];
	let c = -1;
	const [isAutoPlayRunning, setIsAutoPlayRunning] = useState(false);
	const [pins, setPins] = useState([]);
	const tridiRef = useRef(null);
	return (
		<div id="features" className={`text-center  ${classNameDependsOnCondtion}`}>
			<div className="container-fluid prod ">
				<div className="row ">
					<div className="col-xl-4  section-titl products_title">
						<br></br>
						<br></br>
						<br></br>
						<h2>{i18n.t('product_title')} </h2>
						<p className="det">{i18n.t('product_paragraph')}</p>
					</div>
					<div className=" col-xl-8 ">
						<div className="d-flex flex-md-row flex-column">
							{props.data
								? props.data.map((d, i) => (
										<div
											key={`${d.title}-${i}`}
											id="grow"
											className={`products col   ${padd_product}`}
										>
											<div
												className="row product_title"
												style={{ padding: '10' }}
											>
												<h2>{i18n.t(d.title)}</h2>
												<h5>{i18n.t(d.status)}</h5>
											</div>
											<div
												className="row"
												style={{
													width: 'calc(10em + 18vmin)',
													height: 'calc(8em + 12vmin)',
													padding: '10',
												}}
											>
												<Tridi
													ref={tridiRef}
													location={d.icon}
													format={d.type}
													count={d.size}
													style={{}}
												/>
											</div>
											<div
												className="row "
												style={{ padding: '10', marginTop: '90' }}
											>
												<p>{i18n.t(d.text)}</p>
												<div style={{ visibility: 'hidden' }}>{`${(c =
													-1)} `}</div>
											</div>
										</div>
								  ))
								: 'Loading...'}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

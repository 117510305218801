import i18n from '../i18n';

let classNameDependsOnCondtion = i18n.language == 'ar' ? 'arabic' : '';

export const Footer = (props) => {
	return (
		<footer class="footer">
			<div
				className={`container-fluid bottom_border ${classNameDependsOnCondtion}`}
			>
				<div class="row">
					<div class=" col-lg-5 ">
						<a href="#page-top">
							<img
								src={'img/farmtopia.png'}
								style={{ width: 'calc(8em + 15vmin)', marginTop: '-50' }}
								alt="logo"
							/>
						</a>
						<div class="align-links">
							<p>{props.data ? i18n.t(props.data.paragraph) : 'Loading'}</p>
						</div>
					</div>

					<div className=" col-lg-3 justify-content-center footer-titl">
						<h2>
							<span className="highlight">{i18n.t('Useful')}</span>{' '}
							{i18n.t('Links')}{' '}
						</h2>

						<div class="align-links">
							<ul className="footer_ul_amrc">
								<li className="nav-item">
									<a href="#page-top" className="nav-link">
										<span>
											<img
												alt="right-sided arrow"
												src={'img/next.png'}
												style={{ width: '30px' }}
											/>
										</span>{' '}
										{i18n.t('nav_home')}
									</a>
								</li>
								<li className="nav-item">
									<a href="#about" className="nav-link">
										<span>
											<img
												alt="right-sided arrow"
												src={'img/next.png'}
												style={{ width: '30px' }}
											/>
										</span>{' '}
										{i18n.t('nav_about')}
									</a>
								</li>
								<li className="nav-item">
									<a href="#features" className="nav-link">
										<span>
											<img
												alt="right-sided arrow"
												src={'img/next.png'}
												style={{ width: '30px' }}
											/>
										</span>{' '}
										{i18n.t('nav_products')}
									</a>
								</li>
								<li className="nav-item">
									<a href="#services" className="nav-link">
										<span>
											<img
												alt="right-sided arrow"
												src={'img/next.png'}
												style={{ width: '30px' }}
											/>
										</span>{' '}
										{i18n.t('nav_services')}
									</a>
								</li>
								<li className="nav-item">
									<a href="#blog" className="nav-link">
										<span>
											<img
												alt="right-sided arrow"
												src={'img/next.png'}
												style={{ width: '30px' }}
											/>
										</span>{' '}
										{i18n.t('nav_blog')}
									</a>
								</li>
								<li className="nav-item">
									<a href="#contact" className="nav-link">
										<span>
											<img
												alt="right-sided arrow"
												src={'img/next.png'}
												style={{ width: '30px' }}
											/>
										</span>{' '}
										{i18n.t('nav_contact')}
									</a>
								</li>

								<li className="nav-item">
									<a
										href="https://beta.platform.farmtopia.farm/terms"
										className="nav-link"
									>
										<span>
											<img
												alt="right-sided arrow"
												src={'img/next.png'}
												style={{ width: '30px' }}
											/>
										</span>{' '}
										{i18n.t('nav_terms')}
									</a>
								</li>
							</ul>
						</div>
					</div>

					<div class=" col-lg-4 footer-titl">
						<h2>
							<span className="highlight">{i18n.t('Contact')}</span>{' '}
							{i18n.t('Info')}{' '}
						</h2>

						<div class="align-links">
							<div className="contact-info">
								<div className="contact-item">
									<p>
										<span>
											<img
												src={'img/phone.png'}
												style={{ width: '30px', marginLeft: '5px' }}
											/>{' '}
										</span>{' '}
										{props.data ? props.data.phone : 'loading'}
									</p>
								</div>
								<div className="contact-item">
									<p>
										<span>
											<img
												src={'img/envelope.png'}
												style={{ width: '30px', marginLeft: '5px' }}
											/>{' '}
										</span>{' '}
										{props.data ? props.data.email : 'loading'}
									</p>
								</div>
								<div className="contact-item">
									<p>
										<span>
											<img
												src={'img/location.png'}
												style={{ width: '30px', marginLeft: '5px' }}
											/>{' '}
										</span>{' '}
										{i18n.t(props.data ? props.data.address : 'loading')}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

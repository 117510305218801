// react leaflet version 4.0.1"

import React, { useEffect, useState } from 'react';
import L from 'leaflet';
import {
	LayersControl,
	MapContainer,
	TileLayer,
	WMSTileLayer,
	FeatureGroup,
	useMapEvents,
	// LayerGroup,
	// Circle,
	// Marker,
	// Popup,
	// Rectangle,
	// useMap,
} from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import Fullscreen from 'react-leaflet-fullscreen-plugin';

import i18n from '../i18n';

import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';

// Fix The Marker Broken Image Issue
L.Icon.Default.mergeOptions({
	iconRetinaUrl:
		'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png',
	iconUrl:
		'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png',
	shadowUrl:
		'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-shadow.png',
});

const Map = () => {
	const [isWMSChecked, setIsWMSChecked] = useState(false);
	const [isOSMChecked, setIsOSMChecked] = useState(true);
	const [zoomLevel, setZoomLevel] = useState(13);
	const [mapShapes, setMapShapes] = useState([]);

	const ZoomLevelCalculator = () => {
		const mapEvents = useMapEvents({
			zoomend: () => {
				setZoomLevel(mapEvents.getZoom());
			},
		});

		const inputs = document.getElementsByClassName(
			'leaflet-control-layers-selector'
		);

		useEffect(() => {
			if (zoomLevel <= 9) {
				console.log('InnerZoomLever: ', zoomLevel);
				if (inputs && inputs.length > 0) {
					Array.prototype.forEach.call(inputs, function (input, index) {
						if (index !== 0) {
							input.disabled = true;
						}
					});
				}
				if (zoomLevel <= 8) {
					setIsWMSChecked(false);
					setIsOSMChecked(true);
				}
			} else if (zoomLevel > 9) {
				setIsOSMChecked(false);
				setIsWMSChecked(true);
			}
		}, [zoomLevel]);
		return null;
	};

	const createShapeHandler = (e) => {
		const { layerType, layer } = e;

		// Implement the same functionality for the rest of the shapes
		// just make sure what shapes we will use
		if (layerType === 'polyline') {
			const { _leaflet_id } = layer;
			setMapShapes((layers) => [
				...layers,
				{
					id: _leaflet_id,
					layerType: layerType,
					latlngs: layer.getLatLngs()[0],
				},
			]);
		}

		if (layerType === 'polygon') {
			const { _leaflet_id } = layer;
			setMapShapes((layers) => [
				...layers,
				{
					id: _leaflet_id,
					layerType: layerType,
					latlngs: layer.getLatLngs()[0],
				},
			]);
		}

		if (layerType === 'rectangle') {
			const { _leaflet_id } = layer;
			setMapShapes((layers) => [
				...layers,
				{
					id: _leaflet_id,
					layerType: layerType,
					latlngs: layer.getLatLngs()[0],
				},
			]);
		}

		if (layerType === 'marker') {
			const { _leaflet_id } = layer;
			setMapShapes((layers) => [
				...layers,
				{
					id: _leaflet_id,
					layerType: layerType,
					latlngs: layer._latlng,
				},
			]);
		}

		if (layerType === 'circlemarker') {
			const { _leaflet_id } = layer;
			setMapShapes((layers) => [
				...layers,
				{
					id: _leaflet_id,
					layerType: layerType,
					latlngs: layer._latlng,
				},
			]);
		}
	};

	const editShapeHandler = (e) => {
		// console.log('edited');
		// console.log(e);

		const {
			layers: { _layers },
		} = e;

		Object.values(_layers).map(({ _leaflet_id, editing }) => {
			setMapShapes((layers) =>
				layers.map((layer) =>
					layer.id === _leaflet_id
						? { ...layer, latlngs: { ...editing.latlngs[0] } }
						: layer
				)
			);
		});
	};

	const deleteShapeHandler = (e) => {
		// console.log('deleted');
		// console.log(e);

		const {
			layers: { _layers },
		} = e;

		Object.values(_layers).map(({ _leaflet_id }) => {
			setMapShapes((layers) =>
				layers.filter((layer) => layer.id !== _leaflet_id)
			);
		});
	};

	return (
		<div id="map">
			<MapContainer
				center={[26.80592, 27.92965]}
				zoom={13}
				minZoom={2}
				scrollWheelZoom={true}
				style={{ height: '85vh', width: '100wh' }}
				// className="non-clickable"
			>
				<ZoomLevelCalculator />

				{/* Drawing Tools */}
				<FeatureGroup>
					<EditControl
						position="topleft"
						onCreated={createShapeHandler}
						// onEdited={editShapeHandler}
						// onDeleted={deleteShapeHandler}
					/>
				</FeatureGroup>

				{/* Fullscreen Icon */}
				{/* <Fullscreen position="bottomleft" forceSeparateButton={true} /> */}

				<TileLayer
					attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
					url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
					// className="hidden"
				/>

				<LayersControl
					// collapsed={false}
					position="topright"
					className="control__layer-item"
				>
					{/* 1. Default Map OpenStreenMaps LAYER */}
					<LayersControl.BaseLayer
						name={i18n.t('default_map')}
						checked={isOSMChecked ? true : false}
					>
						<TileLayer
							attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
							url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
						/>
					</LayersControl.BaseLayer>

					{/* 2. NATURAL-COLOR WMS LAYER */}
					<LayersControl.BaseLayer
						checked={isWMSChecked ? true : false}
						name={i18n.t('natural_color')}
						disabled={true}
					>
						<WMSTileLayer
							layers="NATURAL-COLOR"
							url="https://services.sentinel-hub.com/ogc/wms/83aac752-2f7d-49b6-9c53-9de05fb41275?REQUEST=GetMap&BBOX=3238005,5039853,3244050,5045897&MAXCC=95&WIDTH=1280&HEIGHT=720&FORMAT=image/jpeg'"
						/>
					</LayersControl.BaseLayer>

					{/* <LayerGroup> */}
					{/* 3. NDVI WMS LAYER */}
					<LayersControl.BaseLayer name={i18n.t('ndvi')}>
						<WMSTileLayer
							layers="NDVI"
							url="https://services.sentinel-hub.com/ogc/wms/83aac752-2f7d-49b6-9c53-9de05fb41275?REQUEST=GetMap&BBOX=3238005,5039853,3244050,5045897&MAXCC=95&WIDTH=1280&HEIGHT=720&FORMAT=image/jpeg'"
						/>
					</LayersControl.BaseLayer>

					{/* 4. ENDVI WMS LAYER */}
					<LayersControl.BaseLayer name={i18n.t('endvi')}>
						{/* <LayerGroup> */}
						<WMSTileLayer
							layers="ENDVI"
							url="https://services.sentinel-hub.com/ogc/wms/83aac752-2f7d-49b6-9c53-9de05fb41275?REQUEST=GetMap&BBOX=3238005,5039853,3244050,5045897&MAXCC=95&WIDTH=1280&HEIGHT=720&FORMAT=image/jpeg'"
						/>
					</LayersControl.BaseLayer>

					{/* 5. MOISTURE-INDEX WMS LAYER */}
					<LayersControl.BaseLayer name={i18n.t('moisture_index')}>
						<WMSTileLayer
							layers="MOISTURE-INDEX"
							url="https://services.sentinel-hub.com/ogc/wms/83aac752-2f7d-49b6-9c53-9de05fb41275?REQUEST=GetMap&BBOX=3238005,5039853,3244050,5045897&MAXCC=95&WIDTH=1280&HEIGHT=720&FORMAT=image/jpeg'"
						/>
					</LayersControl.BaseLayer>

					{/* 6. LAI-SAVI WMS LAYER */}
					<LayersControl.BaseLayer name={i18n.t('lai_savi')}>
						<WMSTileLayer
							layers="LAI-SAVI"
							url="https://services.sentinel-hub.com/ogc/wms/83aac752-2f7d-49b6-9c53-9de05fb41275?REQUEST=GetMap&BBOX=3238005,5039853,3244050,5045897&MAXCC=95&WIDTH=1280&HEIGHT=720&FORMAT=image/jpeg'"
						/>
					</LayersControl.BaseLayer>

					{/* 7. SAVI-2 WMS LAYER */}
					<LayersControl.BaseLayer name={i18n.t('msavi_2')}>
						<WMSTileLayer
							layers="MSAVI2"
							url="https://services.sentinel-hub.com/ogc/wms/83aac752-2f7d-49b6-9c53-9de05fb41275?REQUEST=GetMap&BBOX=3238005,5039853,3244050,5045897&MAXCC=95&WIDTH=1280&HEIGHT=720&FORMAT=image/jpeg'"
						/>
					</LayersControl.BaseLayer>

					{/* 8. AI-Classification WMS LAYER */}
					<LayersControl.BaseLayer name={i18n.t('ai_classification')}>
						<WMSTileLayer
							layers="AI_VISUALIZATION"
							url="https://services.sentinel-hub.com/ogc/wms/83aac752-2f7d-49b6-9c53-9de05fb41275?REQUEST=GetMap&BBOX=3238005,5039853,3244050,5045897&MAXCC=95&WIDTH=1280&HEIGHT=720&FORMAT=image/jpeg'"
						/>
					</LayersControl.BaseLayer>
				</LayersControl>
			</MapContainer>
		</div>
	);
};

export default Map;

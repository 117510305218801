import i18n, { changeLang, getlang, setLang } from '../i18n';

// import googlePlay from '../../public/img/getInGooglePlay.png';

let classNameDependsOnCondtion = i18n.language == 'ar' ? 'arabic' : 'ml-auto';
let reverse = i18n.language == 'ar' ? 'rtl' : 'ltr';

const changeLangMode = (lang = null) => {
	if (lang) {
		changeLang(lang);
	} else {
		let currentLang = getlang();
		if (currentLang == 'en') {
			changeLang('ar');
		} else {
			changeLang('en');
		}
	}
	window.location.reload(true);
};

// window.onscroll = function () {
// 	scrollFunction();
// };

// function scrollFunction() {
// 	if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
// 		document.getElementById('menu').style.background = '#fff';
// 		document.getElementById('lang_btn').style.color = '#1e4b2c';
// 		var links = document.getElementsByName('links');
// 		// console.log(links);
// 		for (var i = 0, max = links.length; i < max; i++) {
// 			links[i].style.color = '#1e4b2c';
// 		}
// 	} else {
// 		document.getElementById('menu').style.background = 'rgba(3, 98, 8, 0.2)';
// 		document.getElementById('lang_btn').style.color = '#fff';
// 		var links = document.getElementsByName('links');
// 		// console.log(links);
// 		for (var i = 0, max = links.length; i < max; i++) {
// 			links[i].style.color = '#fff';
// 		}
// 	}
// }

export const Navigation = (props) => {
	return (
		// navbar navbar-expand-md navbar-light
		<nav id="menu" className="navigation-bar_new default" dir="ltr">
			<a class={`navbar-brand mr-auto`} href="#page-top">
				<img
					src={'img/farmtopia.png'}
					style={{ width: '150px', marginTop: '-15px' }}
				/>
			</a>
			<button
				class="navbar-toggler "
				type="button"
				data-toggle="collapse"
				data-target="#navbarSupportedContent"
			>
				<span class="navbar-toggler-icon"></span>
			</button>

			<div className="collapse navbar-collapse" id="navbarSupportedContent">
				<ul
					style={{ direction: `${reverse}` }}
					className={`navbar-nav ml-auto flex-md-row ${classNameDependsOnCondtion}`}
				>
					<li class="nav-item">
						<a href="#page-top" name="links" className="nav-link">
							{i18n.t('nav_home')}
						</a>
					</li>
					<li class="nav-item">
						<a href="#about" name="links" className="nav-link">
							{i18n.t('nav_about')}
						</a>
					</li>
					<li class="nav-item">
						<a href="#features" name="links" className="nav-link">
							{i18n.t('nav_products')}
						</a>
					</li>

					<li class="nav-item">
						<a href="#services" name="links" className="nav-link">
							{i18n.t('nav_services')}
						</a>
					</li>
					<li class="nav-item">
						<a
							href={`https://blog.farmtopia.farm`}
							name="links"
							className="nav-link"
						>
							{i18n.t('nav_blog')}
						</a>
					</li>

					<li class="nav-item">
						<a href="#map" name="links" className="nav-link">
							{i18n.t('nav_map')}
						</a>
					</li>

					<li class="nav-item">
						<a href="#contact" name="links" className="nav-link">
							{i18n.t('nav_contact')}
						</a>
					</li>
				</ul>

				<button id="lang_btn" onClick={() => changeLangMode()}>
					{i18n.t('lang')}
				</button>
			</div>

			<div>
				<a href="https://beta.platform.farmtopia.farm">
					<div>
						<button className="login-btn">
							Platform
							<span className="btn-badge">NEW</span>
						</button>
					</div>
				</a>

				<a href="https://play.google.com/store/apps/details?id=farm.farmtopia.app">
					<div className="google-play">
						<img
							src={'img/getInGooglePlay.png'}
							alt="googlPlay"
							width={142}
							height={55}
						/>
						<span className="googlePlay-badge">NEW</span>
					</div>
				</a>
			</div>
		</nav>
	);
};

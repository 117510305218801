import i18n from "../i18n";
let classNameDependsOnCondtion = i18n.language == 'ar' ? "arabic" : "";


export const About = (props) => {
  return (
    <div id='about'>
      <div className='container-fluid'>
        
        <div className='row ' >
        <div class="col-lg-6">
          <br></br><br></br><br></br>
          <div >
  <img className="iframe" src={"img/about-us-gif.gif"}/>
  </div>
</div>
          <div className='col-lg-6'>
            <div className={`about-text ${classNameDependsOnCondtion }`}>
              
            <h2> {i18n.t("about_title")}</h2>
              <p>{props.data ? i18n.t("about_paragraph") : 'loading...'}</p>
              
            </div>
          </div>
        </div>
        
      </div>
    </div>
  )
}

import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: 'AIzaSyANeyAO7d6GZo1k_A9uh1uD4X2xRZ3R5lg',
	authDomain: 'farm-topia.firebaseapp.com',
	databaseURL:
		'https://farm-topia-default-rtdb.europe-west1.firebasedatabase.app',
	projectId: 'farm-topia',
	storageBucket: 'farm-topia.appspot.com',
	messagingSenderId: '489430515357',
	appId: '1:489430515357:web:0f8b5ad7819576111e9e74',
	measurementId: 'G-BQ01W9HDLN',
};

const app = initializeApp(firebaseConfig);

const db = getDatabase(app);

export default db;

import i18n from "../i18n";
let classNameDependsOnCondtion = i18n.language == 'ar' ? "arabic " : "";
let padd = i18n.language == 'ar' ? "padd_text" : "";
export const Header = (props) => {
  return (
    <div>
    <header id='header' >
      <div className='intro'>
        <div className='overlay'>
          <div className='container-fluid'>
            <div className='row'>
              <div className={`col-md-10  intro-text ${classNameDependsOnCondtion} ${padd}`}>
                <h1>
                  {i18n.t("Header_title")}
                </h1>
                <h2>
                {i18n.t("Header_paragraph")}
                </h2>

              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div id='about'>
      <div className='container-fluid'>
        
        <div className='row ' >
        
          <div className='col-lg-6'>
            <div className={`about-text ${classNameDependsOnCondtion }`}>
              
            <h2> {i18n.t("about_title")}</h2>
              <p>{props.data ? i18n.t("about_paragraph") : 'loading...'}</p>
              
            </div>
          </div>
          <div class="col-lg-6">

          <div >
  <img className="iframe" src={"img/about-us-gif.gif"}/>
  </div>
</div>
        </div>
        
      </div>
    </div>
    </div>
  )
}

import i18n from "../i18n";
let classNameDependsOnCondtion = i18n.language == 'ar' ? "service_arabic blog_text" : "";
export const Blogs = (props) => {
    return (
      <div id='blogs' className='text-center' >
        <div className={`container-fluid ${classNameDependsOnCondtion }`}>
          <div className='section-titl'> 
            <h2>{i18n.t("Blogs & News")}</h2>
            <h3>{i18n.t("What's going to be on our blog")}</h3>
            
          </div>
          <div className='row justify-content-center ser ' >
            
            {props.data
              ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className='col-md-4'  >
                  <div style={{overflow: 'hidden' ,borderRadius: '15px'}}>
                  <img src={d.icon} className='blog-image' alt='' />{' '}
                  </div>
                  <br></br><br></br>
                  <div className='blog-desc'>
                  <h2>{i18n.t(d.name)}</h2>
                    <p>{i18n.t(d.text)}</p>
                    
                  </div>
                </div>
              ))
              : 'loading'}
          </div>
        </div>
      </div>
    )
  }
  